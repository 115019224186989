import React from "react"
import "./PropertyResultsFeatured.scss"
import { Container } from "react-bootstrap"
import PropertyCard from "@components/PropertyCard/PropertyCard"
import Slider from "@components/elements/Slider/Slider"
import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import useLimitProperties from "@hooks/dataHooks/useLimitProperties"
import { isEmpty } from "lodash"
import {  getUserobjectData, isAuthenticated  } from "@starberry/myaccount-website-utils"

const PropertyResultsFeatured = () => {
  const { propertiesForSale, propertiesToLet } = useLimitProperties()

  const pType = usePropertyStore((state) => state.pType)
  const propertiesToShow =
    pType === "sales" ? propertiesForSale : propertiesToLet
    const [userObjects, setUserObjects] = useState({});
    useEffect(() => {
      if (isAuthenticated()) {
        const getUserObjects = async () => {
            try {
                const userObjects = await getUserobjectData()
                setUserObjects(userObjects.data.data)
            } catch (e) {
                console.log("e:", e)
            }
        }
        getUserObjects()
      }
    }, [])
  return (
    !isEmpty(propertiesToShow) &&
    <div
      className="search-featured-properties-wrap"
      key={`search-featured-properties${pType}`}
    >
      <Container className="search-featured-properties-container">
        <h2 className="title">Featured Listings</h2>

        <Slider size="sm" className="search-featured-propertie-slider">
          {propertiesToShow?.map((property, index) => (
            <PropertyCard
              key={property.id + pType}
              address={property.display_address}
              ammenities={property.accommodation_summary}
              description={property.description}
              bathrooms={property.bathroom}
              bedrooms={property.bedroom}
              building={property.building}
              image={property.images && property.images[0]}
              images={property?.images}
              price={property.price}
              size={property.floorarea_min}
              index={index}
              crmID={property?.crm_id}
              bitrix_id={property?.extra?.bitrix_id}
              slug={property?.slug}
              searchType={property?.search_type}
              imagetransforms={property?.ggfx_results}
              strapiID={property?.strapi_id}
              email={property?.crm_negotiator_id?.email}
              crm_negotiator_id={property?.crm_negotiator_id?.id}
              name={property?.crm_negotiator_id?.name}
              department={property?.department}
              phone={property?.crm_negotiator_id?.phone}
              slider
              singnatureProp={property?.search_type === "sales" && property?.price >= 20000000 && property?.department === "residential" ? true : false}
              userObjects={userObjects}
              link_to_employee={property?.link_to_employee}
            />
          ))}
        </Slider>
      </Container>
    </div>
  )
}

export default PropertyResultsFeatured
