import Layout from "@components/Layout/Layout"
import PropertyMortgageCalc from "@components/PropertyMortgageCalc/PropertyMortgageCalc"
import PropertyResultsFeatured from "@components/PropertyResultsFeatured/PropertyResultsFeatured"
import AmenitiesSelect from "@components/SearchResults/AmenitiesSelect/AmenitiesSelect"
import InfoMapSort from "@components/SearchResults/InfoMapSort/InfoMapSort"
import PropertyList from "@components/SearchResults/PropertyList/PropertyList"
import SearchFilters from "@components/SearchResults/SearchFilters/SearchFilters"
import SearchPagination from "@components/SearchResults/SearchPagination/SearchPagination"
import useInitPropertyData from "@components/SearchResults/hooks/useInitPropertyData"
import useUrlData from "@components/SearchResults/hooks/useUrlData"
import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import PropertyCopy from "@components/TextCopy/PropertyCopy"
import { getH1Text, getSeoDesc } from "@lib/searchUtils"
import { propertiesRentURL, propertiesSaleURL, propertyRentRegex, propertySaleRegex } from "@lib/urls"
import loadable from "@loadable/component"
import "@styles/search-results.scss"
import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import logo from "@images/logo.svg"
import ResultsBreadcrumb from "@components/PropertyBreadcrumb/ResultsBreadcrumb"
import { navigate } from "gatsby"
import QuestionnaireBannerCom from "@components/QuestionnaireBannerCom/QuestionnaireBannerCom"
import useHasScrolled from "@hooks/useHasScrolled"
import { UserObjectStoreProvider, getUserobjectData, isAuthenticated  } from "@starberry/myaccount-website-utils"
const PropertyMap = loadable(() =>
  import("../components/SearchResults/map/RenderMap")
)

const SearchResultsPage = ({ location }) => {
  const scrolled = useHasScrolled()
  const [isLoad, setLoad] = useState(false)
  useEffect(() => {
    setLoad(true)
  })
  const path =
    location.pathname ||
    (typeof window !== "undefined" && window.location.pathname)
    const pageurl = typeof window !== "undefined" ? window.location.href : ""

    useEffect(() => {
      if(typeof window !== "undefined"){
        sessionStorage.setItem("searchpath", path)
      }
    }, [path])
  useInitPropertyData()
  const pageUrlData = useUrlData()
  let h1Text;
  if(pageUrlData){
    h1Text = getH1Text(pageUrlData)
  }
  const seoDesc = getSeoDesc(pageUrlData)
  const showMap = usePropertyStore((state) => state.showMap)
  const pType = usePropertyStore((state) => state.pType)
  const pDepartment = usePropertyStore((state) => state.pDepartment)
  const selectedAreas = usePropertyStore((state) => state.selectedAreas)
  const buildingType = usePropertyStore((state) => state.buildingType)
  const searchResult = usePropertyStore((state) => state.searchResult)
  const { nbHits, nbPages, page } = searchResult
  // check if the url is valid, else redirect to 404
  if (!(path.match(propertyRentRegex) || path.match(propertySaleRegex))) {
    typeof window !== "undefined"  && path?.includes("/buy/") && navigate("/buy/properties-for-sale/", { replace: true })
    typeof window !== "undefined"  && path?.includes("/let/") && navigate("/let/properties-for-rent/", { replace: true })
  }

  // if (!path.match(propertySaleRegex)) {
  //   typeof window !== "undefined" && path?.includes("/buy/") && navigate("/buy/properties-for-sale/", { replace: true })
  // }
  // if (!path.match(propertyRentRegex)) {
  //   typeof window !== "undefined" && path?.includes("/let/") && navigate("/let/properties-for-rent/", { replace: true })
  // }


  const filterApplied =
    path !== (pType === "sales" ? propertiesSaleURL : pType === "lettings" ? propertiesRentURL : propertiesSaleURL)

  const showCopy = (typeof window !== "undefined" && path?.includes("/properties"))
    const listingpage = (typeof window !== "undefined" && window.location.href)

    var ldJson = {
      "@context": "https://schema.org/",
      "@id": "SearchResultsPage",
      "@type": [
        "SearchResultsPage",
        "Product"
      ],
      "accessModeSufficient": {
        "@type": "ItemList",
        "name": h1Text,
        "itemListOrder": "https://schema.org/ItemListOrderDescending",
        "numberOfItems": nbHits,
        "itemListElement": []
      },
      "brand": {
        "@type": "Organization",
        "name": "Provident Estate"
      },
      "breadcrumb": {
        "@id": pageurl+"#breadcrumb",
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "name": "Provident Estate",
            "item": process.env.GATSBY_SITE_URL,
            "position": 1
          },
          {
            "@type": "ListItem",
            "name": h1Text,
            "item": listingpage,
            "position": 2
          }
        ]
      },
      "contentLocation": {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Dubai"
        }
      },
      "description": seoDesc,
      "image": "https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/x.dev/1773x/888_4af09da9b9.webp",
      "name": h1Text,
      "offers": {
        "@type": "AggregateOffer",
        "highPrice": "999999999",
        "lowPrice": "100000",
        "offerCount": nbHits,
        "priceCurrency": "AED"
      },
      "sameAs": "https://en.wikipedia.org/wiki/Search_engine_results_page",
      "speakable": {
        "@type": "SpeakableSpecification",
        "xpath": [
          "/html/head/meta[@name='description']/@content"
        ]
      }
     

    }

    var ldjson1 = {
      "@context": "http://schema.org",
      "@id": "Organization",
      "@type": [
        "Organization",
        "Corporation"
      ],
      "logo": logo,
      "url": process.env.GATSBY_SITE_URL,
      "brand": {
        "@type": "Brand",
        "name": "Provident Estate"
      },
      "name": "Provident Estate",
      "address": "#1802, 18th Floor, Marina Plaza, Dubai Marina, Dubai, UAE",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+971505390249",
        "contactType": "customer service",
        "areaServed": "ae",
        "availableLanguage": [
          "en",
          "ar"
        ]
      },
      "sameAs": [
        "https://www.facebook.com/providentestate",
        "https://www.instagram.com/providentestate",
        "https://www.linkedin.com/company/providentestate",
        "https://www.youtube.com/@Providentestate",
        "https://twitter.com/providentagents"
      ]
    }
    const [userObjects, setUserObjects] = useState({});
    useEffect(() => {
      if (isAuthenticated()) {
        const getUserObjects = async () => {
            try {
                const userObjects = await getUserobjectData()
                setUserObjects(userObjects.data.data)
            } catch (e) {
                console.log("e:", e)
            }
        }
        getUserObjects()
      }
    }, [])
  return (
    <Layout
      pageData={{
        page_class: clsx(
          "search-results-page",
          nbHits === 0 && "no-properties-page",
          scrolled && "search-fit"
        ),
        layout: "search-results",
      }}
      hideNewsletter
      
    >
       <Helmet>
  <script type="application/ld+json">
    {JSON.stringify(ldJson, null, 2)}
  </script>
  <script id="organization-schema" type="application/ld+json">
  {JSON.stringify(ldjson1, null, 2)}
  </script>
</Helmet>
      {showMap ? (
        <div className={clsx("search-map-wrapper")}>
          <PropertyMap />
        </div>
      ) : (
        <>
        <div className="se-r">
        <UserObjectStoreProvider>
          <SearchFilters istrue userObjects={userObjects}/>
          </UserObjectStoreProvider>
          {/* {nbHits !== undefined &&
          <div className="dd-div"> */}
          {/* <AmenitiesSelect /> */}
          {/* <ResultsBreadcrumb path={path} search_type={pType} isLoad={isLoad} h1Text={h1Text ? h1Text : ""} department={pDepartment} /> */}
          {/* {!filterApplied && <PropertyResultsFeatured />} */}
          <div className="search-results-section">
          
          <InfoMapSort h1Text={h1Text} isLoad={isLoad} istrue pType={pType} />
         
            <PropertyList selectedAreas={selectedAreas}  userObjects={userObjects}/>
            {nbPages > page &&
            <SearchPagination />}
          </div>
          </div>
          <div className="results-calculator section-m">
            <Container>
              <PropertyMortgageCalc
                pageComp
                heading="Mortgage Calculator"
                title="Calculate your monthly mortgage repayments"
              />
              
          
            </Container>
          </div>
          <Container>
          <QuestionnaireBannerCom />
          </Container>
            <PropertyCopy
              pType={pType}
              buildingType={buildingType}
              pDepartment={pDepartment}
              selectedAreas={selectedAreas}
              isProperty
            />
          {/* </div>  */}
          {/* // : <div className="dd-loader"></div> */}
          {/* // } */}
        </>
      )}
    </Layout>
  )
}

const SearchResultComp = (props) => {
  return(
      <UserObjectStoreProvider>
          <SearchResultsPage {...props} />
      </UserObjectStoreProvider>
  )
}
export default SearchResultComp
