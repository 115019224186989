import Animation from "@components/elements/Animation"
import GGFXImage from "@components/elements/GGFXImage"
import { parseHTML } from "@lib/utils"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./QuestionnaireBannerCom.scss"
import QuestionnaireModal from "@components/Questionnaire/QuestionnaireModal"
import { graphql, useStaticQuery } from "gatsby"
const QuestionnaireBannerCom = () => {


  const data = useStaticQuery(graphql`
    query {
      strapiQuestionnaireForm {
        strapi_id
        banner {
          image {
            url
          }
          title
          cta_label
          content {
            data {
              content
            }
          }
        }
      ggfx_results {
        ...STRAPI_GGFX_RESULTFragment
      }

      }
    }
  `)

  
  const {
    title,
    content,
    image,
    cta_label,
    // Imagetransforms: imagetransforms,
    // strapi_id: strapiID,

  } = data?.strapiQuestionnaireForm?.banner

  const Wrapper =  "div"


  return (
    <div className="qes-bk com">
    <Wrapper>
    <Animation
      className=""
      wrapperClassName={clsx(
        "question-banner-wrap")}
    >

      <div className="question-banner-container">
        <div className="bg-img">
          <GGFXImage
            ImageSrc={image}
            altText={title}
            imagetransforms={data?.strapiQuestionnaireForm?.ggfx_results}
            renderer="srcSet"
            imagename="questionnaire-form.banner.image"
            strapiID={data?.strapiQuestionnaireForm?.strapi_id}
          />
        </div>

        <div className="content-section">
        <div className="div-pad">
          <div className="content">

            <p className="title">{title}</p>
            {content?.data?.content && (
              <div className="description">
                {parseHTML(content.data.content)}
              </div>
            )}
          </div>
          <div className="cta-section">
            <QuestionnaireModal cta_label={cta_label} />
            <div className="help-info">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 6V12H16.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="url(#paint0_linear_9303_7430)" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_9303_7430" x1="12" y1="3" x2="12" y2="21" gradientUnits="userSpaceOnUse">
      <stop stop-color="#07224B"/>
      <stop offset="1" stop-color="#EA6C2E"/>
    </linearGradient>
  </defs>
</svg>
            It takes less than 2 minutes
            </div>
            </div>
            </div>
        </div>
      </div>


    </Animation>
    </Wrapper>
    </div>
  )
}

export default QuestionnaireBannerCom
